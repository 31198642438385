export const environment = {
  production: true,
  hmr: false,
  debug: false,
  client: 'Mercedes-Benz',
  hostName: 'analytics.mbusaleads.com',
  environmentName: 'production',
  appName: 'Mercedes-Benz Analytics',
  printUser: 'print@shiftdigital.com',
  printPassword: 'ShiftDigital#1',
  ssoPassword: 'ShiftDigital#123',
  applicationInsights: {
    instrumentationKey: '55fd2959-2570-4f00-9899-d41a74ff16ad'
  },
  defaultLocale: 'en',
  availableLocales: ['en'],
  timeZone: 'Eastern Standard Time',
  showLocaleSelector: 'false',
  numberOfMonthsToShowDefault: 12,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1],
  closeRateLookbackMonths: 3,
  defaultChartColors: ['#000', '#737373', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://mbusa-analytics-api-prod.azurewebsites.net/api',
  authUri: 'https://mbusa-analytics-api-prod.azurewebsites.net/token',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: false,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/mercedes-us/favicon.ico',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false
};
